//ヘッダー
$(function() {
    var headerH = $("#header").outerHeight(true);
    function fixedAnime() {
        var scroll = $(window).scrollTop();
        if (scroll >= headerH){
            $('#header').addClass('header__scroll');
        }else{
            $('#header').removeClass('header__scroll');
        }    
    }

    $(window).scroll(function () {
        fixedAnime();
    });

    $(window).on('load', function () {
        fixedAnime();
    });
});

// ハンバーガーメニュー
$(function() {
    'use strict';

    var $hamburgerBtn = $('#js-menu');
    var $hamburgerMenu = $('#js-drawer');

    $hamburgerBtn.on('click', function() {
        $('body').stop().toggleClass('menu-active');
        $hamburgerMenu.slideToggle(300);
        if ($(this).attr('aria-expanded') == 'false') {
            $(this).attr('aria-expanded', true);
        } else {
            $(this).attr('aria-expanded', false);
        }
    });
});

// スムーススクロール
$(function(){
    $('a[href*="#"]').click(function() {
        var elmHash = $(this).attr('href');
        var pos = $(elmHash).offset().top-88;
        $('body,html').animate({scrollTop: pos}, 500);
        return false;
    });
});

// FAQアコーディオン
$(function(){
    $(".accordion dt").on("click", function() {
    $(this).next().slideToggle();
    $(this).children('button').toggleClass("open");
    });
});
